import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import MenuHeader from '../components/MenuHeader'
import TopSocial from '../components/TopSocial'

export default function Gallery() {
    return (
        <>
            <TopSocial />
            <MenuHeader />
            <nav
                className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
                id="ftco-navbar"
            >
                <div className="container">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#ftco-nav"
                        aria-controls="ftco-nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="fa fa-bars" /> Menu
                    </button>
                    <div className="order-lg-last">
                        <Link to="/#appointmentRef" className="btn btn-primary">
                            Make an appointment
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link to={'/'} className="nav-link">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/about'} className="nav-link">
                                    About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/team'} className="nav-link">
                                    Team
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/services'} className="nav-link">
                                    Services
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={'/departments'} className="nav-link">
                                    Departments
                                </Link>
                            </li> */}
                            <li className="nav-item active">
                                <Link to={'/gallery'} className="nav-link">
                                    Gallery
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/blogList'} className="nav-link">
                                    Blog
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/contact'} className="nav-link">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* END nav */}
            <section
                className="hero-wrap hero-wrap-2"
                style={{ backgroundImage: 'url("images/bg_2.jpg")' }}
                data-stellar-background-ratio="0.5"
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs mb-2">
                                <span className="mr-2">
                                    <Link to={'/'}>
                                        Home <i className="fa fa-chevron-right" />
                                    </Link>
                                </span>{" "}
                                <span>
                                    Gallery <i className="fa fa-chevron-right" />
                                </span>
                            </p>
                            <h1 className="mb-0 bread">Gallery</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section ftco-no-pt ftco-no-pb">
                <div className="container-fluid px-md-0">
                    <div className="row no-gutters">
                        <div className="col-md-3">
                            <div
                                className="work img d-flex align-items-center"
                                style={{ backgroundImage: "url(images/work-1.jpg)" }}
                            >
                                <a
                                    href="images/work-1.jpg"
                                    className="icon image-popup d-flex justify-content-center align-items-center"
                                >
                                    <span className="fa fa-expand" />
                                </a>
                                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                                    <div className="text w-100 mb-3 mt-4">
                                        <h2>
                                            <a href="work-single.html">Foot Pain</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div
                                className="work img d-flex align-items-center"
                                style={{ backgroundImage: "url(images/work-2.jpg)" }}
                            >
                                <a
                                    href="images/work-2.jpg"
                                    className="icon image-popup d-flex justify-content-center align-items-center"
                                >
                                    <span className="fa fa-expand" />
                                </a>
                                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                                    <div className="text w-100 mb-3 mt-4">
                                        <h2>
                                            <a href="work-single.html">Arm Pain</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div
                                className="work img d-flex align-items-center"
                                style={{ backgroundImage: "url(images/work-3.jpg)" }}
                            >
                                <a
                                    href="images/work-3.jpg"
                                    className="icon image-popup d-flex justify-content-center align-items-center"
                                >
                                    <span className="fa fa-expand" />
                                </a>
                                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                                    <div className="text w-100 mb-3 mt-4">
                                        <h2>
                                            <a href="work-single.html">Shoulder Pain</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div
                                className="work img d-flex align-items-center"
                                style={{ backgroundImage: "url(images/work-4.jpg)" }}
                            >
                                <a
                                    href="images/work-4.jpg"
                                    className="icon image-popup d-flex justify-content-center align-items-center"
                                >
                                    <span className="fa fa-expand" />
                                </a>
                                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                                    <div className="text w-100 mb-3 mt-4">
                                        <h2>
                                            <a href="work-single.html">Back Pain</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div
                                className="work img d-flex align-items-center"
                                style={{ backgroundImage: "url(images/work-5.jpg)" }}
                            >
                                <a
                                    href="images/work-5.jpg"
                                    className="icon image-popup d-flex justify-content-center align-items-center"
                                >
                                    <span className="fa fa-expand" />
                                </a>
                                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                                    <div className="text w-100 mb-3 mt-4">
                                        <h2>
                                            <a href="work-single.html">Head &amp; Migraines</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div
                                className="work img d-flex align-items-center"
                                style={{ backgroundImage: "url(images/work-6.jpg)" }}
                            >
                                <a
                                    href="images/work-6.jpg"
                                    className="icon image-popup d-flex justify-content-center align-items-center"
                                >
                                    <span className="fa fa-expand" />
                                </a>
                                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                                    <div className="text w-100 mb-3 mt-4">
                                        <h2>
                                            <a href="work-single.html">Wear &amp; Tear</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div
                                className="work img d-flex align-items-center"
                                style={{ backgroundImage: "url(images/work-7.jpg)" }}
                            >
                                <a
                                    href="images/work-7.jpg"
                                    className="icon image-popup d-flex justify-content-center align-items-center"
                                >
                                    <span className="fa fa-expand" />
                                </a>
                                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                                    <div className="text w-100 mb-3 mt-4">
                                        <h2>
                                            <a href="work-single.html">Arm Pain</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div
                                className="work img d-flex align-items-center"
                                style={{ backgroundImage: "url(images/work-8.jpg)" }}
                            >
                                <a
                                    href="images/work-8.jpg"
                                    className="icon image-popup d-flex justify-content-center align-items-center"
                                >
                                    <span className="fa fa-expand" />
                                </a>
                                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                                    <div className="text w-100 mb-3 mt-4">
                                        <h2>
                                            <a href="work-single.html">Lower Back Pain</a>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}
