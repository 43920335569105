import React from 'react'

export default function AboutPhysiotherapy() {
  return (
    <section style={{marginTop:100}} className="ftco-section ftco-no-pt ftco-no-pb">
        <div className="container">
          <div className="row d-flex no-gutters">
            <div className="col-md-6 d-flex order-md-last">
              <div className="img img-video d-flex align-self-stretch align-items-center justify-content-center mb-4 mb-sm-0" style={{ backgroundImage: 'url(images/about.jpg)' }}>
                <a href="https://vimeo.com/45830194" target="_blank" rel="noopener noreferrer" className="icon-video popup-vimeo d-flex justify-content-center align-items-center">
                  <span className="fa fa-play" />
                </a>
              </div>
            </div>
            <div className="col-md-6 pr-md-5 py-md-5">
              <div className="row justify-content-start py-5">
                <div className="col-md-12 heading-section ">
                  <span className="subheading">Welcome to Physiotherapy</span>
                  <h2 className="mb-4">About Physiotherapy</h2>
                  <p>Physiotherapy, or physical therapy, is a healthcare profession that enhances physical abilities and manages pain. Physiotherapists assess, diagnose, and treat various conditions, using techniques like manual therapy, therapeutic exercises, and modalities such as ultrasound or electrical stimulation. They promote holistic recovery, considering physical, psychological, and social aspects. With personalized treatment plans, physiotherapists empower individuals to actively participate in their own rehabilitation, improving function and quality of life. It's a regulated profession that requires qualified practitioners. Consulting a physiotherapist can be a valuable step towards recovery and improved physical well-being.</p>
                </div>
              </div>
              <div className="row ftco-counter pb-5" id="section-counter">
                <div className="col-md-6 col-lg-4 d-flex justify-content-center counter-wrap ">
                  <div className="block-18">
                    <div className="text">
                      <strong className="number" data-number={45}>4</strong>
                    </div>
                    <div className="text">
                      <span>Years of <br />Experienced</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex justify-content-center counter-wrap ">
                  <div className="block-18">
                    <div className="text">
                      <strong className="number" data-number={2342}>100+</strong>
                    </div>
                    <div className="text">
                      <span>Happy <br />Customers</span>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 col-lg-4 d-flex justify-content-center counter-wrap ">
                  <div className="block-18">
                    <div className="text">
                      <strong className="number" data-number={30}>0</strong>
                    </div>
                    <div className="text">
                      <span>Award <br />Winning</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}
