import moment from 'moment/moment';
import React, { forwardRef, useState } from 'react'
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { APPOINMENT_FORM_LINK, servicies } from '../utils/commons';

const Appointment = forwardRef((props, ref) => {
  const [startDate, setStartDate] = useState();
  const [date, setDate] = useState();
  const [startTime, setStartTime] = useState();
  const [time, setTime] = useState();
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [service, setService] = useState();

  // const addDataToForm = async () => {
  //   var formId = "1FAIpQLSf8B7YiYwDcePNR0E8xIbtrsJIGYamljyOr0LDoQQmJljXqUQ";
  //   var apiUrl = "https://docs.google.com/forms/d/e/" + formId + "/formResponse";
  //   const data = new URLSearchParams();
  //   data.append('entry.587823138', name);
  //   data.append('entry.873984752', number);
  //   data.append('entry.1973361999', service);
  //   data.append('entry.975804678', date + ' ' + time);
  //   try {
  //     console.log("data", data);
  //     const response = await axios.post(apiUrl, data, {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //         'Access-Control-Allow-Origin': '*',
  //       },
  //     });
  //     console.log(response.status);
  //     toast.success("Thank you for submitting your appointment request. We will notify you on the phone number you provided once the doctor schedules your appointment on your preferred date and time")
  //     setStartDate(moment().add('1', 'days').toDate())
  //     setDate('')
  //     setStartTime(moment().add('1', 'days').toDate())
  //     setTime('')
  //     setName('')
  //     setNumber('')
  //     setService("Select Services")
  //   } catch (error) {
  //     toast.error("Something went wrong. Please try again later")
  //     console.error(error);
  //   }
  // }

  return (
    <section ref={ref} className="ftco-appointment ftco-section ftco-no-pt ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row d-md-flex justify-content-center">
          <div className="col-md-12">
            <div className="wrap-appointment d-md-flex">
              <div className="col-md-8 bg-primary p-5 heading-section heading-section-white">
                <span className="subheading">Booking an Appointment</span>
                <h2 className="mb-4">Free Consultation</h2>
                <iframe onLoad={() => {
                  toast.success("Thank you for submitting your appointment request. We will notify you on the phone number you provided once the doctor schedules your appointment on your preferred date and time")
                  setStartDate(moment().add('1', 'days').toDate())
                  setDate('')
                  setStartTime(moment().add('1', 'days').toDate())
                  setTime('')
                  setName('')
                  setNumber('')
                  setService("Select Services")
                }} name='hiddenConfirm' id='hiddenConfirm' style={{ display: 'none' }}></iframe>
                <form method="post" target="hiddenConfirm" action={APPOINMENT_FORM_LINK} className="appointment">
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name='entry.587823138'
                          value={name} onChange={event => setName(event.target.value)} required
                          type="text" className="form-control" placeholder="Your Name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name='entry.873984752'
                          inputMode='tel'
                          pattern="^[0-9\-\+\s\(\)]*$"
                          required
                          value={number} onChange={event => setNumber(event.target.value)}
                          type="tel" className="form-control" placeholder="Phone number" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-field">
                          <div className="select-wrap">
                            <div className="icon"><span className="fa fa-chevron-down" /></div>
                            <select
                              value={service}
                              onChange={(event) => {
                                setService(event.target.value)
                              }}
                              name='entry.1973361999'
                              className="form-control">
                              <option value="Select Services">Select Services</option>
                              {
                                servicies && servicies.length > 0 && servicies.map((item, index) => (
                                  <option value={item.name}>{item.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-field">
                          <div className="select-wrap">
                            <div className="icon"><span className="fa fa-chevron-down" /></div>
                            <select name='entry.1777856248' className="form-control">
                              <option value='Dr. Payal Patel'>Dr. Payal Patel</option>
                              <option value='Dr. Devanshi Parmar'>Dr. Devanshi Parmar</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="input-wrap">
                          <div className="icon"><span className="fa fa-calendar" /></div>
                          <DatePicker
                            className="form-control appointment_date"
                            selected={startDate}
                            placeholderText="Date"
                            dateFormat="dd/MM/yyyy"
                            minDate={moment().toDate()}
                            onChange={(date) => {
                              setStartDate(date)
                              setDate(moment(date.getTime()).format("DD/MM/yyyy"))
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="input-wrap">
                          <div className="icon"><span className="fa fa-clock-o" /></div>
                          <DatePicker
                            className="form-control appointment_time"
                            selected={startTime}
                            placeholderText="Time"
                            onChange={(date) => {
                              setStartTime(date)
                              setTime(moment(date.getTime()).format("h:mm:ss a"))
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            minDate={moment().toDate()}
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <input type='hidden' name='entry.975804678' value={date + ' ' + time} />
                    <div className="col-md-6">
                      <div className="form-group">
                        <input type="submit" defaultValue="Send message" className="btn btn-secondary py-3 px-4" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-4 bg-white text-center p-5">
                <div className="desc border-bottom pb-4">
                  <h2>Business Hours</h2>
                  <div className="opening-hours">
                    <h4>Opening Days:</h4>
                    <p className="pl-3">
                      <span><strong>Monday – Saturday:</strong> 8am to 20 pm</span>
                    </p>
                    <h4>Vacations:</h4>
                    <p className="pl-3">
                      <span>All Sunday Days</span>
                      <span>All Official Holidays</span>
                    </p>
                  </div>
                </div>
                <div className="desc pt-4 ">
                  <h3 className="heading">For Emergency Cases</h3>
                  <span className="phone">(+91) 9586233839</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Appointment
