import React from 'react'

export default function TopSocial() {
    return (
        <div className="top py-1" style={{ marginTop: 10 }}>
            <div className="container">
                <div className="row">
                    <div className="col d-flex align-items-center">

                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <div className="social-media">
                            <p className="mb-0 d-flex">
                                <a href="https://www.facebook.com/swasthyamphysiotherapy/" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center justify-content-center"><span className="fa fa-facebook"><i className="sr-only">Facebook</i></span></a>
                                <a href="https://www.instagram.com/swasthyamfitness/" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center justify-content-center"><span className="fa fa-instagram"><i className="sr-only">Twitter</i></span></a>
                                <a href="https://twitter.com/swasthyamfitnes/" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center justify-content-center"><span className="fa fa-twitter"><i className="sr-only">Instagram</i></span></a>
                                <a href="https://www.youtube.com/channel/UCRFDa_AAtGsPiAxXhfvKIHg" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center justify-content-center"><span className="fa fa-youtube"><i className="sr-only">YouTube</i></span></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
