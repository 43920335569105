import React from 'react'
import { servicieDetails } from '../utils/commons'

export default function Footer() {
  return (
    <footer className="footer ftco-section ftco-no-pt">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget py-4 py-md-5">
              <h2 className="logo"><a href="#">Physiotherapy</a></h2>
              <p>Writing quotes is physiotherapy for the brain.</p>
              <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-4">
                <li className=""><a href="https://twitter.com/swasthyamfitnes/" target="_blank"><span className="fa fa-twitter" /></a></li>
                <li className=""><a href="https://www.facebook.com/swasthyamphysiotherapy/" target="_blank"><span className="fa fa-facebook" /></a></li>
                <li className=""><a href="https://www.instagram.com/swasthyamfitness/" target="_blank"><span className="fa fa-instagram" /></a></li>
                <li className=""><a href="https://www.youtube.com/channel/UCRFDa_AAtGsPiAxXhfvKIHg" target="_blank"><span className="fa fa-youtube" /></a></li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget ml-md-5 py-5">
              <h2 className="ftco-heading-2">Services</h2>
              <ul className="list-unstyled">
                {
                  servicieDetails && servicieDetails.length > 0 && servicieDetails.map((item, index) => (
                    <li><a href="#" className="py-1 d-block"><span className="fa fa-check mr-3" />{item.name}</a></li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget py-4 py-md-5">
              <h2 className="ftco-heading-2">Contact information</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li><span className="icon fa fa-map-marker" /><span className="text"> <a href="https://goo.gl/maps/Eure824qTBC37o8H9" target="_blank" rel="noopener noreferrer">
                    Swasthyam Physiotherapy Center, Shop No 4, Sky View Heights, Chhapra Rd, Navsari, Gujarat 396445</a></span></li>
                  <li><a href="tel://+91 9586233839"><span className="icon fa fa-phone" /><span className="text">+91 9586233839</span></a></li>
                  <li><a href="mailto:swasthyamphysiotherapy@gmail.com"><span className="icon fa fa-paper-plane" /><span className="text">swasthyamphysiotherapy@gmail.com</span></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget bg-primary p-4 py-5">
              <h2 className="ftco-heading-2">Business Hours</h2>
              <div className="opening-hours">
                <h4>Opening Days:</h4>
                <p className="pl-3">
                  <span>Monday – Saturday :<br />8 am to 8pm</span>
                </p>
                <h4>Vacations:</h4>
                <p className="pl-3">
                  <span>All Sunday Days</span><br />
                  <span>All Official Holidays</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="text-center text-md-end text-xl-start">
              All Rights Reserved @Swasthyam Physiotherapy Center
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
