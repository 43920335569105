import React from 'react'

export default function NotFound() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>404 Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
      {/* You can add additional content or styling here */}
    </div>
  )
}
