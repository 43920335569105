import React, { useState, useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Footer from '../components/Footer'
import MenuHeader from '../components/MenuHeader'
import TopSocial from '../components/TopSocial'
import { servicieDetails, servicies } from '../utils/commons'
import IconComponent from '../utils/IconComponent'

export default function Services() {
    let { state } = useLocation();
    const serviceRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(state && state.position ? state.position : null);
    const [selectedExerciseDetails, setSelectedExerciseDetails] = useState(state && state.position ? state.position : null);

    const handleItemClick = (index) => {
        setSelectedItem(index);
        setSelectedExerciseDetails(index);
    };

    useEffect(() => {
        if (serviceRef.current && selectedExerciseDetails != null) {
            serviceRef.current.focus();
        }
    }, [selectedItem, selectedExerciseDetails]);


    return (
        <>
            <TopSocial />
            <MenuHeader />
            <nav
                className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
                id="ftco-navbar"
            >
                <div className="container">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#ftco-nav"
                        aria-controls="ftco-nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="fa fa-bars" /> Menu
                    </button>
                    <div className="order-lg-last">
                        <Link to="/#appointmentRef" className="btn btn-primary">
                            Make an appointment
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link to={'/'} className="nav-link">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/about'} className="nav-link">
                                    About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/team'} className="nav-link">
                                    Team
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link to={'/services'} className="nav-link">
                                    Services
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={'/departments'} className="nav-link">
                                    Departments
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={'/gallery'} className="nav-link">
                                    Gallery
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/blogList'} className="nav-link">
                                    Blog
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/contact'} className="nav-link">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* END nav */}
            <section
                className="hero-wrap hero-wrap-2"
                style={{ backgroundImage: 'url("images/bg_2.jpg")' }}
                data-stellar-background-ratio="0.5"
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs mb-2">
                                <span className="mr-2">
                                    <Link to={'/'}>
                                        Home <i className="fa fa-chevron-right" />
                                    </Link>
                                </span>{" "}
                                <span>
                                    Services <i className="fa fa-chevron-right" />
                                </span>
                            </p>
                            <h1 className="mb-0 bread">Services</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section">
                <div className="container" tabIndex={-1} ref={serviceRef}>
                    <div className="row justify-content-center mb-5">
                        <div className="col-md-8 text-center heading-section">
                            <span className="subheading">Services</span>
                            <h2 className="mb-4">Why Choose Us?</h2>
                            <p>
                                Experience personalized care at Swasthyam Physiotherapy Center. Our expert physiotherapists, state-of-the-art facilities, and compassionate approach ensure tailored solutions for your unique needs. Trust us for a healthier, pain-free life.
                            </p>
                        </div>
                    </div>
                    <div className="row tabulation mt-4">
                        <div className="col-md-4">
                            <ul className="nav nav-pills nav-fill d-block flex-column scroll-style" style={{ maxHeight: '550px', overflowY: 'auto', flexDirection: 'column' }} >
                                {
                                    servicies && servicies.length > 0 && servicies.map((item, index) => (
                                        <li className="nav-item text-left">
                                            <a className="nav-link py-4" data-toggle="tab" href={item.ref}
                                                onClick={() => handleItemClick(index)}>
                                                <IconComponent
                                                    iconSrc={selectedItem === index ? `images/${item.icon}-white.png` : `images/${item.icon}.png`}
                                                    width={50}
                                                    height={50} /> {item.name}
                                            </a>
                                        </li>
                                    ))

                                }
                            </ul>
                        </div>
                        <div className="col-md-8">
                            <div className="tab-content">
                                {
                                    servicieDetails && servicieDetails.length > 0 && servicieDetails.map((item, index) => (
                                        <div
                                            className={`tab-pane container p-0 ${selectedExerciseDetails != null && selectedExerciseDetails == index ? 'active' : selectedExerciseDetails == null && index == 0 ? 'active' : 'fade'}`}
                                            id={item.ref}>
                                            <div
                                                className="img"
                                                style={{ backgroundImage: "url(images/work-2.jpg)" }}
                                            />
                                            <h3>
                                                <a href="#">{item.name}</a>
                                            </h3>
                                            <p>
                                                {item.details}
                                            </p>
                                        </div>
                                    ))

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
