import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import { toast } from 'react-toastify';
import MenuHeader from '../components/MenuHeader'
import TopSocial from '../components/TopSocial'
import { CONTACT_FORM_LINK } from '../utils/commons'

export default function Contact() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState();
    const [subject, setSubject] = useState();
    const [message, setMessage] = useState();

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;

        // Check if the input matches the desired pattern (only digits)
        if (/^\d*$/.test(inputValue)) {
            setPhoneNumber(inputValue);
        }
    };

    return (
        <>
            <TopSocial />
            <MenuHeader />
            <nav
                className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
                id="ftco-navbar"
            >
                <div className="container">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#ftco-nav"
                        aria-controls="ftco-nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="fa fa-bars" /> Menu
                    </button>
                    <div className="order-lg-last">
                        <Link to="/#appointmentRef" className="btn btn-primary">
                            Make an appointment
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link to={'/'} className="nav-link">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/about'} className="nav-link">
                                    About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/team'} className="nav-link">
                                    Team
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/services'} className="nav-link">
                                    Services
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={'/departments'} className="nav-link">
                                    Departments
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={'/gallery'} className="nav-link">
                                    Gallery
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/blogList'} className="nav-link">
                                    Blog
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link to={'/contact'} className="nav-link">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* END nav */}
            <section
                className="hero-wrap hero-wrap-2"
                style={{ backgroundImage: 'url("images/bg_2.jpg")' }}
                data-stellar-background-ratio="0.5"
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs mb-2">
                                <span className="mr-2">
                                    <Link to={'/'}>
                                        Home <i className="fa fa-chevron-right" />
                                    </Link>
                                </span>{" "}
                                <span>
                                    Contact us <i className="fa fa-chevron-right" />
                                </span>
                            </p>
                            <h1 className="mb-0 bread">Contact us</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        {/* <div className="col-md-12">
                            <div id="map" className="map" />
                        </div> */}
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="row no-gutters">
                                    <div className="col-md-7 d-flex">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            <h3 className="mb-4">Get in touch</h3>
                                            <iframe onLoad={() => {
                                                toast.success("Thank you for submitting your query. We will reach you soon on the phone number you provided.")
                                                setPhoneNumber('')
                                                setName('')
                                                setMessage('')
                                                setSubject('')
                                            }} name='hiddenConfirm' id='hiddenConfirm' style={{ display: 'none' }}></iframe>
                                            <form method="post" target="hiddenConfirm" action={CONTACT_FORM_LINK} className="contactForm">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name='entry.2005620554'
                                                                id="name"
                                                                placeholder="Name"
                                                                value={name} onChange={event => setName(event.target.value)} required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                type="tel"
                                                                className="form-control"
                                                                name='entry.1166974658'
                                                                id="phone"
                                                                inputMode='tel'
                                                                value={phoneNumber}
                                                                onChange={handlePhoneChange}
                                                                pattern="\d*"
                                                                placeholder="Phone number"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name='entry.1065046570'
                                                                id="subject"
                                                                placeholder="Subject"
                                                                value={subject} onChange={event => setSubject(event.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <textarea
                                                                name='entry.839337160'
                                                                className="form-control"
                                                                id="message"
                                                                cols={30}
                                                                rows={7}
                                                                placeholder="Message"
                                                                defaultValue={""}
                                                                value={message} onChange={event => setMessage(event.target.value)} required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input
                                                                type="submit"
                                                                defaultValue="Send Message"
                                                                className="btn btn-primary"
                                                            />
                                                            <div className="submitting" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-5 d-flex align-items-stretch">
                                        <div className="info-wrap bg-primary w-100 p-lg-5 p-4">
                                            <h3 className="mb-4 mt-md-4">Contact us</h3>
                                            <div className="dbox w-100 d-flex align-items-start">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-map-marker" />
                                                </div>
                                                <div className="text pl-3">
                                                    <p>
                                                        <span>Address:</span> <span> <a href="https://goo.gl/maps/Eure824qTBC37o8H9" target="_blank" rel="noopener noreferrer">
                                                            Swasthyam Physiotherapy Center, Shop No 4, Sky View Heights, Chhapra Rd, Navsari, Gujarat 396445</a></span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dbox w-100 d-flex align-items-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-phone" />
                                                </div>
                                                <div className="text pl-3">
                                                    <p>
                                                        <span>Phone:</span>{" "}
                                                        <a href="tel://+91 9586233839"><span>+91 9586233839</span></a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dbox w-100 d-flex align-items-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-paper-plane" />
                                                </div>
                                                <div className="text pl-3">
                                                    <p>
                                                        <span>Email:</span>{" "}
                                                        <a href="mailto:swasthyamphysiotherapy@gmail.com">swasthyamphysiotherapy@gmail.com</a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dbox w-100 d-flex align-items-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-globe" />
                                                </div>
                                                <div className="text pl-3">
                                                    <p>
                                                        <span>Website</span> <a href="https://swasthyamfitness.com/">swasthyamfitness.com</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}
