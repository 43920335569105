export const APPOINMENT_FORM_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLSf8B7YiYwDcePNR0E8xIbtrsJIGYamljyOr0LDoQQmJljXqUQ/formResponse'
export const CONTACT_FORM_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLSeGFEPkWbBK4Ok6_7M8zOemiEkEhz4GUwvYUbr2oz4iLWY1cA/formResponse'

export const servicies = [
    { name: 'Exercise Therapy', ref: 'exercise-therapy', icon: 'exercise-therapy', shortDescription: 'Exercise therapy in physiotherapy enhances mobility, strength, and flexibility, crucial for rehabilitation and well-being.' },
    { name: 'Manual Therapy', ref: 'manual-therapy', icon: 'manual-therapy', shortDescription: 'Manual therapy in physiotherapy improves musculoskeletal function, reduces pain, and enhances overall well-being through hands-on techniques.' },
    { name: 'Electrotherapy', ref: 'electrotherapy', icon: 'electrotherapy', shortDescription: 'Electrotherapy in physiotherapy employs electrical stimulation to manage pain, promote tissue healing, and enhance muscle function.' },
    { name: 'Joint Mobilization Therapy', ref: 'joint-mobilization-therapy', icon: 'joint-mobilization', shortDescription: 'Joint mobilization therapy in physiotherapy improves joint movement, reduces stiffness, and enhances function through gentle, controlled manual techniques.' },
    { name: 'Pain Management', ref: 'pain-management', icon: 'pain-management', shortDescription: 'Pain management in physiotherapy employs diverse strategies, including exercises, modalities, and education, to alleviate discomfort and improve overall well-being.' },
    { name: 'Cupping Therapy', ref: 'cupping-therapy', icon: 'cupping-therapy', shortDescription: 'Cupping therapy in physiotherapy involves creating suction on the skin to promote blood flow, reduce muscle tension, and enhance healing.' },
    { name: 'Dry Needling', ref: 'dry-needling', icon: 'dry-needling', shortDescription: 'Dry needling in physiotherapy uses thin needles to target trigger points, relieving pain and improving muscle function.' },
    { name: 'Kinesio Taping', ref: 'kinesio-taping', icon: 'exercise-therapy', shortDescription: 'Kinesio taping in physiotherapy involves applying elastic tape to support muscles, reduce pain, and enhance joint stability during movement.' },
    { name: 'Pelvic Floor Exercise', ref: 'pelvic-floor-exercise', icon: 'pelvic-floor-exercise', shortDescription: 'Pelvic floor exercises strengthen and support the muscles in the pelvic region, promoting bladder and bowel control.' },
    { name: 'ANC and PNC Training', ref: 'anc-pnc', icon: 'anc-pnc-exercise', shortDescription: 'ANC (Antenatal Care) and PNC (Postnatal Care) training focuses on maternal health, covering prenatal and postnatal education and care.' },
    { name: 'Vestibular Rehabilitation', ref: 'vestibular-rehabilitation', icon: 'vestibular', shortDescription: 'Vestibular rehabilitation in physiotherapy addresses balance and dizziness issues through exercises and therapies targeting the vestibular system for improved stability and function.' },
    { name: 'Weight Management', ref: 'weight-management', icon: 'weight-management', shortDescription: 'Weight management in physiotherapy involves tailored exercises, nutrition guidance, and lifestyle changes to achieve and maintain a healthy weight.' },
    { name: 'Fitness Training', ref: 'fitness-training', icon: 'fitness-training', shortDescription: 'Fitness training in physiotherapy encompasses targeted exercises to enhance strength, flexibility, and cardiovascular health, tailored to individual needs and goals.' },
    { name: 'Yoga and Pilates', ref: 'yoga-pilates', icon: 'yoga', shortDescription: 'Yoga and Pilates, integral to physiotherapy, emphasize mindful movements, stretching, and core strengthening, promoting overall well-being and flexibility.' },
    { name: 'Diet and Nutrients', ref: 'diet-nutrients', icon: 'diet', shortDescription: 'In physiotherapy, diet and nutrient guidance play a crucial role, addressing nutrition to support recovery, manage weight, and optimize overall health.' },
]

export const servicieDetails = [
    { name: 'Exercise Therapy', ref: 'exercise-therapy', detailImage: 'work-2.jpg', details: "Exercise therapy in physiotherapy involves the systematic application of specific exercises to address musculoskeletal and neurological conditions. It aims to restore and improve mobility, flexibility, strength, and endurance. Tailored to each patient's needs, it plays a crucial role in rehabilitation, promoting functional recovery and preventing future injuries by targeting specific impairments and promoting overall physical well-being." },
    { name: 'Manual Therapy', ref: 'manual-therapy', detailImage: 'work-2.jpg', details: "In physiotherapy, manual therapy refers to hands-on techniques used by physiotherapists to assess, diagnose, and treat musculoskeletal and neuromuscular conditions. It includes various hands-on interventions like joint mobilization, manipulation, soft tissue massage, and stretching. Manual therapy aims to reduce pain, improve joint mobility, correct movement patterns, and enhance overall function. It is often integrated into a comprehensive treatment plan alongside therapeutic exercises and other modalities." },
    { name: 'Electrotherapy', ref: 'electrotherapy', detailImage: 'work-2.jpg', details: "Electrotherapy in physiotherapy utilizes electrical modalities like TENS and ultrasound for pain relief, muscle stimulation, and tissue healing. Customized for each patient, it improves circulation, aids neuromuscular reeducation, and complements manual therapy. Non-invasive and adaptable, it enhances rehabilitation and empowers patients with at-home programs for continued care." },
    { name: 'Joint Mobilization Therapy', ref: 'joint-mobilization-therapy', detailImage: 'work-2.jpg', details: "Joint mobilization therapy in physiotherapy involves skilled, hands-on techniques to gently move and restore normal joint function. This method aims to alleviate pain, enhance joint flexibility, and improve mobility. Tailored to individual needs, it is a key component of physiotherapeutic interventions for musculoskeletal conditions, promoting overall joint health and well-being." },
    { name: 'Pain Management', ref: 'pain-management', detailImage: 'work-2.jpg', details: "In physiotherapy, pain management focuses on alleviating pain through comprehensive approaches. Therapists employ techniques such as manual therapy, exercise programs, and modalities like TENS or heat therapy. Individualized plans address the root cause, aiming to reduce pain, improve function, and enhance overall well-being, empowering patients in their recovery." },
    { name: 'Cupping Therapy', ref: 'cupping-therapy', detailImage: 'work-2.jpg', details: "Cupping therapy in physiotherapy involves the use of suction cups on the skin to create a vacuum effect. This technique aims to increase blood flow, reduce muscle tension, and promote healing. Physiotherapists may integrate cupping into treatment plans for conditions like musculoskeletal pain, with the goal of enhancing overall rehabilitation and relieving discomfort." },
    { name: 'Dry Needling', ref: 'dry-needling', detailImage: 'work-2.jpg', details: "Dry needling in physiotherapy is a technique that involves inserting thin needles into trigger points or tight muscles to release tension and alleviate pain. This method aims to stimulate the body's natural healing processes, reduce muscle tightness, and improve range of motion. Integrated into physiotherapeutic care, dry needling is often used for musculoskeletal conditions to enhance overall recovery." },
    { name: 'Kinesio Taping', ref: 'kinesio-taping', detailImage: 'work-2.jpg', details: 'Kinesio taping in physiotherapy involves applying elastic tape to specific areas of the body to support muscles, joints, and facilitate movement. This technique aims to reduce pain, enhance circulation, and provide structural support without restricting motion. Physiotherapists use kinesio taping as part of a comprehensive treatment plan to optimize healing and improve functional outcomes for various musculoskeletal conditions.' },
    { name: 'Pelvic Floor Exercise', ref: 'pelvic-floor-exercise', detailImage: 'work-2.jpg', details: "Pelvic floor exercises in physiotherapy focus on strengthening and promoting the flexibility of the muscles that support the pelvic organs. Tailored to address conditions like incontinence or pelvic pain, these exercises enhance muscle control and coordination. Physiotherapists guide individuals in performing targeted pelvic floor exercises to improve function, alleviate symptoms, and enhance overall pelvic health." },
    { name: 'ANC and PNC Training', ref: 'anc-pnc', detailImage: 'work-2.jpg', details: "ANC (Antenatal Care) and PNC (Postnatal Care) training in physiotherapy involves educating pregnant and postpartum individuals on exercises and therapeutic techniques to promote optimal health. Physiotherapists guide women through tailored programs, addressing issues such as back pain, pelvic floor dysfunction, and posture during pregnancy. This training aims to enhance overall well-being and facilitate a smoother transition to postnatal recovery." },
    { name: 'Vestibular Rehabilitation', ref: 'vestibular-rehabilitation', detailImage: 'work-2.jpg', details: "Vestibular rehabilitation in physiotherapy is a specialized approach targeting the vestibular system, which contributes to balance and spatial orientation. Therapists use exercises and maneuvers to alleviate symptoms of dizziness, vertigo, and imbalance associated with vestibular disorders. Tailored to individual needs, this rehabilitation aims to improve gaze stability, enhance postural control, and promote overall functional recovery for those with vestibular issues." },
    { name: 'Weight Management', ref: 'weight-management', detailImage: 'work-2.jpg', details: "Weight management in physiotherapy involves comprehensive strategies to address obesity and its impact on physical health. Physiotherapists integrate exercise programs, lifestyle modifications, and behavioral interventions to support weight loss and improve overall well-being. This approach aims to enhance mobility, reduce the risk of musculoskeletal issues, and promote sustainable lifestyle changes for optimal weight management." },
    { name: 'Fitness Training', ref: 'fitness-training', detailImage: 'work-2.jpg', details: "Fitness training in physiotherapy encompasses tailored exercise programs designed to improve overall health and physical well-being. Physiotherapists assess individual needs and create customized regimens that may include cardiovascular, strength, flexibility, and balance exercises. This approach not only aids recovery from injuries but also fosters sustainable fitness habits, preventing future health issues." },
    { name: 'Yoga and Pilates', ref: 'yoga-pilates', detailImage: 'work-2.jpg', details: "In physiotherapy, yoga and Pilates are incorporated as therapeutic exercises to improve flexibility, strength, and body awareness. Physiotherapists use elements of these practices to enhance rehabilitation, emphasizing controlled movements and mindful breathing. Tailored to individual needs, yoga and Pilates contribute to improved posture, core stability, and overall functional fitness, promoting holistic well-being." },
    { name: 'Diet and Nutrients', ref: 'diet-nutrients', detailImage: 'work-2.jpg', details: "In physiotherapy, diet and nutrient education are integral components of holistic care. Physiotherapists provide guidance on nutrition to support overall health and healing. Emphasizing the importance of a balanced diet, they address how specific nutrients impact musculoskeletal health, inflammation, and recovery. This comprehensive approach enhances the effectiveness of physiotherapeutic interventions and promotes optimal well-being." },
]