import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import MenuHeader from '../components/MenuHeader'
import TopSocial from '../components/TopSocial'

export default function Blog() {
    return (
        <>
            <TopSocial />
            <MenuHeader />
            <nav
                className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
                id="ftco-navbar"
            >
                <div className="container">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#ftco-nav"
                        aria-controls="ftco-nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="fa fa-bars" /> Menu
                    </button>
                    <div className="order-lg-last">
                        <Link to="/#appointmentRef" className="btn btn-primary">
                            Make an appointment
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link to={'/'} className="nav-link">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/about'} className="nav-link">
                                    About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/team'} className="nav-link">
                                    Team
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/services'} className="nav-link">
                                    Services
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={'/departments'} className="nav-link">
                                    Departments
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={'/gallery'} className="nav-link">
                                    Gallery
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link to={'/blogList'} className="nav-link">
                                    Blog
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/contact'} className="nav-link">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* END nav */}
            <section
                className="hero-wrap hero-wrap-2"
                style={{ backgroundImage: 'url("images/bg_2.jpg")' }}
                data-stellar-background-ratio="0.5"
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs mb-2">
                                <span className="mr-2">
                                    <Link to={'/'}>
                                        Home <i className="fa fa-chevron-right" />
                                    </Link>
                                </span>{" "}
                                <span className="mr-2">
                                    <Link to={'/blog'}>
                                        Blog <i className="fa fa-chevron-right" />
                                    </Link>
                                </span>{" "}
                                <span>
                                    Blog Single <i className="fa fa-chevron-right" />
                                </span>
                            </p>
                            <h1 className="mb-0 bread">Blog Single</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section ftco-degree-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <p>
                                <img src="images/image_1.jpg" alt="" className="img-fluid" />
                            </p>
                            <h2 className="mb-3">
                                It is a long established fact a reader be distracted
                            </h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Reiciendis, eius mollitia suscipit, quisquam doloremque distinctio
                                perferendis et doloribus unde architecto optio laboriosam porro
                                adipisci sapiente officiis nemo accusamus ad praesentium? Esse
                                minima nisi et. Dolore perferendis, enim praesentium omnis, iste
                                doloremque quia officia optio deserunt molestiae voluptates soluta
                                architecto tempora.
                            </p>
                            <p>
                                Molestiae cupiditate inventore animi, maxime sapiente optio, illo
                                est nemo veritatis repellat sunt doloribus nesciunt! Minima laborum
                                magni reiciendis qui voluptate quisquam voluptatem soluta illo eum
                                ullam incidunt rem assumenda eveniet eaque sequi deleniti tenetur
                                dolore amet fugit perspiciatis ipsa, odit. Nesciunt dolor minima
                                esse vero ut ea, repudiandae suscipit!
                            </p>
                            <h2 className="mb-3 mt-5">#2. Creative WordPress Themes</h2>
                            <p>
                                Temporibus ad error suscipit exercitationem hic molestiae totam
                                obcaecati rerum, eius aut, in. Exercitationem atque quidem tempora
                                maiores ex architecto voluptatum aut officia doloremque. Error
                                dolore voluptas, omnis molestias odio dignissimos culpa ex earum
                                nisi consequatur quos odit quasi repellat qui officiis reiciendis
                                incidunt hic non? Debitis commodi aut, adipisci.
                            </p>
                            <p>
                                <img src="images/image_2.jpg" alt="" className="img-fluid" />
                            </p>
                            <p>
                                Quisquam esse aliquam fuga distinctio, quidem delectus veritatis
                                reiciendis. Nihil explicabo quod, est eos ipsum. Unde aut non
                                tenetur tempore, nisi culpa voluptate maiores officiis quis vel ab
                                consectetur suscipit veritatis nulla quos quia aspernatur
                                perferendis, libero sint. Error, velit, porro. Deserunt minus,
                                quibusdam iste enim veniam, modi rem maiores.
                            </p>
                            <p>
                                Odit voluptatibus, eveniet vel nihil cum ullam dolores laborum, quo
                                velit commodi rerum eum quidem pariatur! Quia fuga iste tenetur,
                                ipsa vel nisi in dolorum consequatur, veritatis porro explicabo
                                soluta commodi libero voluptatem similique id quidem? Blanditiis
                                voluptates aperiam non magni. Reprehenderit nobis odit inventore,
                                quia laboriosam harum excepturi ea.
                            </p>
                            <div className="tag-widget post-tag-container mb-5 mt-5">
                                <div className="tagcloud">
                                    <a href="#" className="tag-cloud-link">
                                        Life
                                    </a>
                                    <a href="#" className="tag-cloud-link">
                                        Sport
                                    </a>
                                    <a href="#" className="tag-cloud-link">
                                        Tech
                                    </a>
                                    <a href="#" className="tag-cloud-link">
                                        Travel
                                    </a>
                                </div>
                            </div>
                            <div className="about-author d-flex p-4 bg-light">
                                <div className="bio mr-5">
                                    <img
                                        src="images/person_1.jpg"
                                        alt="Image placeholder"
                                        className="img-fluid mb-4"
                                    />
                                </div>
                                <div className="desc">
                                    <h3>George Washington</h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        Ducimus itaque, autem necessitatibus voluptate quod mollitia
                                        delectus aut, sunt placeat nam vero culpa sapiente consectetur
                                        similique, inventore eos fugit cupiditate numquam!
                                    </p>
                                </div>
                            </div>
                            <div className="pt-5 mt-5">
                                <h3 className="mb-5">6 Comments</h3>
                                <ul className="comment-list">
                                    <li className="comment">
                                        <div className="vcard bio">
                                            <img src="images/person_1.jpg" alt="Image placeholder" />
                                        </div>
                                        <div className="comment-body">
                                            <h3>John Doe</h3>
                                            <div className="meta">February 24, 2020 at 2:21pm</div>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                Pariatur quidem laborum necessitatibus, ipsam impedit vitae
                                                autem, eum officia, fugiat saepe enim sapiente iste iure!
                                                Quam voluptas earum impedit necessitatibus, nihil?
                                            </p>
                                            <p>
                                                <a href="#" className="reply">
                                                    Reply
                                                </a>
                                            </p>
                                        </div>
                                    </li>
                                    <li className="comment">
                                        <div className="vcard bio">
                                            <img src="images/person_1.jpg" alt="Image placeholder" />
                                        </div>
                                        <div className="comment-body">
                                            <h3>John Doe</h3>
                                            <div className="meta">February 24, 2020 at 2:21pm</div>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                Pariatur quidem laborum necessitatibus, ipsam impedit vitae
                                                autem, eum officia, fugiat saepe enim sapiente iste iure!
                                                Quam voluptas earum impedit necessitatibus, nihil?
                                            </p>
                                            <p>
                                                <a href="#" className="reply">
                                                    Reply
                                                </a>
                                            </p>
                                        </div>
                                        <ul className="children">
                                            <li className="comment">
                                                <div className="vcard bio">
                                                    <img src="images/person_1.jpg" alt="Image placeholder" />
                                                </div>
                                                <div className="comment-body">
                                                    <h3>John Doe</h3>
                                                    <div className="meta">February 24, 2020 at 2:21pm</div>
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipisicing
                                                        elit. Pariatur quidem laborum necessitatibus, ipsam
                                                        impedit vitae autem, eum officia, fugiat saepe enim
                                                        sapiente iste iure! Quam voluptas earum impedit
                                                        necessitatibus, nihil?
                                                    </p>
                                                    <p>
                                                        <a href="#" className="reply">
                                                            Reply
                                                        </a>
                                                    </p>
                                                </div>
                                                <ul className="children">
                                                    <li className="comment">
                                                        <div className="vcard bio">
                                                            <img
                                                                src="images/person_1.jpg"
                                                                alt="Image placeholder"
                                                            />
                                                        </div>
                                                        <div className="comment-body">
                                                            <h3>John Doe</h3>
                                                            <div className="meta">
                                                                February 24, 2020 at 2:21pm
                                                            </div>
                                                            <p>
                                                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                                                elit. Pariatur quidem laborum necessitatibus, ipsam
                                                                impedit vitae autem, eum officia, fugiat saepe enim
                                                                sapiente iste iure! Quam voluptas earum impedit
                                                                necessitatibus, nihil?
                                                            </p>
                                                            <p>
                                                                <a href="#" className="reply">
                                                                    Reply
                                                                </a>
                                                            </p>
                                                        </div>
                                                        <ul className="children">
                                                            <li className="comment">
                                                                <div className="vcard bio">
                                                                    <img
                                                                        src="images/person_1.jpg"
                                                                        alt="Image placeholder"
                                                                    />
                                                                </div>
                                                                <div className="comment-body">
                                                                    <h3>John Doe</h3>
                                                                    <div className="meta">
                                                                        February 24, 2020 at 2:21pm
                                                                    </div>
                                                                    <p>
                                                                        Lorem ipsum dolor sit amet, consectetur
                                                                        adipisicing elit. Pariatur quidem laborum
                                                                        necessitatibus, ipsam impedit vitae autem, eum
                                                                        officia, fugiat saepe enim sapiente iste iure!
                                                                        Quam voluptas earum impedit necessitatibus,
                                                                        nihil?
                                                                    </p>
                                                                    <p>
                                                                        <a href="#" className="reply">
                                                                            Reply
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="comment">
                                        <div className="vcard bio">
                                            <img src="images/person_1.jpg" alt="Image placeholder" />
                                        </div>
                                        <div className="comment-body">
                                            <h3>John Doe</h3>
                                            <div className="meta">February 24, 2020 at 2:21pm</div>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                                Pariatur quidem laborum necessitatibus, ipsam impedit vitae
                                                autem, eum officia, fugiat saepe enim sapiente iste iure!
                                                Quam voluptas earum impedit necessitatibus, nihil?
                                            </p>
                                            <p>
                                                <a href="#" className="reply">
                                                    Reply
                                                </a>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                {/* END comment-list */}
                                <div className="comment-form-wrap pt-5">
                                    <h3 className="mb-5">Leave a comment</h3>
                                    <form action="#" className="p-5 bg-light">
                                        <div className="form-group">
                                            <label htmlFor="name">Name *</label>
                                            <input type="text" className="form-control" id="name" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email *</label>
                                            <input type="email" className="form-control" id="email" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="website">Website</label>
                                            <input type="url" className="form-control" id="website" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea
                                                name=""
                                                id="message"
                                                cols={30}
                                                rows={10}
                                                className="form-control"
                                                defaultValue={""}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="submit"
                                                defaultValue="Post Comment"
                                                className="btn py-3 px-4 btn-primary"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>{" "}
                        {/* .col-md-8 */}
                        <div className="col-lg-4 sidebar pl-lg-5">
                            <div className="sidebar-box">
                                <form action="#" className="search-form">
                                    <div className="form-group">
                                        <span className="fa fa-search" />
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Type a keyword and hit enter"
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="sidebar-box">
                                <div className="categories">
                                    <h3>Department</h3>
                                    <li>
                                        <a href="#">
                                            Chiropractic <span className="fa fa-chevron-right" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Acupuncture <span className="fa fa-chevron-right" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            Massage <span className="fa fa-chevron-right" />
                                        </a>
                                    </li>
                                </div>
                            </div>
                            <div className="sidebar-box">
                                <h3>Recent Blog</h3>
                                <div className="block-21 mb-4 d-flex">
                                    <a
                                        className="blog-img mr-4"
                                        style={{ backgroundImage: "url(images/image_1.jpg)" }}
                                    />
                                    <div className="text">
                                        <h3 className="heading">
                                            <a href="#">
                                                Even the all-powerful Pointing has no control about the
                                                blind texts
                                            </a>
                                        </h3>
                                        <div className="meta">
                                            <div>
                                                <a href="#">
                                                    <span className="icon-calendar" />
                                                    Feb. 24, 2020
                                                </a>
                                            </div>
                                            <div>
                                                <a href="#">
                                                    <span className="icon-person" /> Admin
                                                </a>
                                            </div>
                                            <div>
                                                <a href="#">
                                                    <span className="icon-chat" /> 19
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block-21 mb-4 d-flex">
                                    <a
                                        className="blog-img mr-4"
                                        style={{ backgroundImage: "url(images/image_2.jpg)" }}
                                    />
                                    <div className="text">
                                        <h3 className="heading">
                                            <a href="#">
                                                Even the all-powerful Pointing has no control about the
                                                blind texts
                                            </a>
                                        </h3>
                                        <div className="meta">
                                            <div>
                                                <a href="#">
                                                    <span className="icon-calendar" />
                                                    Feb. 24, 2020
                                                </a>
                                            </div>
                                            <div>
                                                <a href="#">
                                                    <span className="icon-person" /> Admin
                                                </a>
                                            </div>
                                            <div>
                                                <a href="#">
                                                    <span className="icon-chat" /> 19
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="block-21 mb-4 d-flex">
                                    <a
                                        className="blog-img mr-4"
                                        style={{ backgroundImage: "url(images/image_3.jpg)" }}
                                    />
                                    <div className="text">
                                        <h3 className="heading">
                                            <a href="#">
                                                Even the all-powerful Pointing has no control about the
                                                blind texts
                                            </a>
                                        </h3>
                                        <div className="meta">
                                            <div>
                                                <a href="#">
                                                    <span className="icon-calendar" />
                                                    Feb. 24, 2020
                                                </a>
                                            </div>
                                            <div>
                                                <a href="#">
                                                    <span className="icon-person" /> Admin
                                                </a>
                                            </div>
                                            <div>
                                                <a href="#">
                                                    <span className="icon-chat" /> 19
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-box">
                                <h3>Tag Cloud</h3>
                                <div className="tagcloud">
                                    <a href="#" className="tag-cloud-link">
                                        chiro
                                    </a>
                                    <a href="#" className="tag-cloud-link">
                                        chiropractic
                                    </a>
                                    <a href="#" className="tag-cloud-link">
                                        chiropractor
                                    </a>
                                    <a href="#" className="tag-cloud-link">
                                        bones
                                    </a>
                                    <a href="#" className="tag-cloud-link">
                                        massage
                                    </a>
                                    <a href="#" className="tag-cloud-link">
                                        acupuncture
                                    </a>
                                </div>
                            </div>
                            <div className="sidebar-box">
                                <h3>Paragraph</h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus
                                    itaque, autem necessitatibus voluptate quod mollitia delectus aut,
                                    sunt placeat nam vero culpa sapiente consectetur similique,
                                    inventore eos fugit cupiditate numquam!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>{" "}
            {/* .section */}
            <Footer />
        </>
    )
}
