import React from 'react'
import { Link } from 'react-router-dom'
import AboutPhysiotherapy from '../components/AboutPhysiotherapy'
import Footer from '../components/Footer'
import MenuHeader from '../components/MenuHeader'
import TopSocial from '../components/TopSocial'

export default function About() {
    return (
        <>
            <TopSocial/>
            <MenuHeader />
            <nav
                className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
                id="ftco-navbar"
            >
                <div className="container">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#ftco-nav"
                        aria-controls="ftco-nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="fa fa-bars" /> Menu
                    </button>
                    <div className="order-lg-last">
                        <Link to="/#appointmentRef" className="btn btn-primary">
                            Make an appointment
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link to={'/'} className="nav-link">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link to={'/about'} className="nav-link">
                                    About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/team'} className="nav-link">
                                    Team
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/services'} className="nav-link">
                                    Services
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={'/departments'} className="nav-link">
                                    Departments
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={'/gallery'} className="nav-link">
                                    Gallery
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/blogList'} className="nav-link">
                                    Blog
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/contact'} className="nav-link">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* END nav */}
            <section
                className="hero-wrap hero-wrap-2"
                style={{ backgroundImage: 'url("images/bg_2.jpg")' }}
                data-stellar-background-ratio="0.5"
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs mb-2">
                                <span className="mr-2">
                                    <Link to={'/'}>
                                        Home <i className="fa fa-chevron-right" />
                                    </Link>
                                </span>{" "}
                                <span>
                                    About us <i className="fa fa-chevron-right" />
                                </span>
                            </p>
                            <h1 className="mb-0 bread">About Us</h1>
                        </div>
                    </div>
                </div>
            </section>
            <AboutPhysiotherapy/>
            <section className="ftco-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>Physiotherapy History</h4>
                            <p>
                            Physiotherapy has a rich history dating back thousands of years. Ancient civilizations, such as the Egyptians, Greeks, and Chinese, practiced various forms of physical therapy to treat injuries and improve health. However, modern physiotherapy emerged as a distinct profession in the late 19th century. The pioneer of modern physiotherapy is considered to be Per Henrik Ling, a Swedish gymnastics instructor who developed the Swedish Movement System. Over time, advancements in medical science and technology have greatly expanded the scope and effectiveness of physiotherapy. Today, it is recognized as an essential healthcare discipline, helping individuals recover from injuries, manage chronic conditions, and improve overall physical well-being.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}
