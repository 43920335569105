import React from 'react'
import { Link } from 'react-router-dom'

export default function MenuHeader() {
    return (
        <div className="wrap">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-3 mb-md-0 mb-4 d-flex align-items-center">
                    <Link to={'/'} className="navbar-brand">Swasthyam Physiotherapy Center</Link>
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-md-8 mb-md-0 mb-3">
                                <div className="top-wrap d-flex">
                                    <div className="icon d-flex align-items-center justify-content-center"><span className="fa fa-location-arrow" /></div>
                                    <div className="text"><span>Address</span><span> <a href="https://goo.gl/maps/Eure824qTBC37o8H9" target="_blank" rel="noopener noreferrer">
                                        Swasthyam Physiotherapy Center, Shop No 4, Sky View Heights, Chhapra Rd, Navsari, Gujarat 396445</a></span></div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="top-wrap d-flex">
                                    <div className="icon d-flex align-items-center justify-content-center"><span className="fa fa-phone" /></div>
                                    <div className="text"><span>Call us</span><a href="tel://+91 9586233839"><span>+91 9586233839</span></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
