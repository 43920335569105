import './App.css';
import Home from './screens/Home';
import { Routes, Route } from "react-router-dom";
import About from './screens/About';
import Team from './screens/Team';
import Services from './screens/Services';
import Departments from './screens/Departments';
import Gallery from './screens/Gallery';
import BlogList from './screens/BlogList';
import Blog from './screens/Blog';
import Contact from './screens/Contact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from './screens/NotFound';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="team" element={<Team />} />
        <Route path="services" element={<Services />} />
        <Route path="departments" element={<Departments />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="blogList" element={<BlogList />} />
        <Route path="blog" element={<Blog />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-center" />
    </div>
  );
}

export default App;
