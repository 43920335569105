import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import MenuHeader from '../components/MenuHeader'
import TopSocial from '../components/TopSocial'
import { InstagramEmbed } from '../instablogs/InstagramEmbed.tsx'
import { doc, getDoc } from "firebase/firestore";
import { db } from '../utils/firebase'

export default function BlogList() {
    const [blogs, setBlogs] = useState([])
    const add = async () => {
        try {
            const docRef = doc(db, "blogs", "blogList");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setBlogs(docSnap.data().instaPosts)
            }
        } catch (e) {
            console.error("Error: ", e);
        }

    }

    useEffect(() => {
        add()
    }, [])
    return (
        <>
            <TopSocial />
            <MenuHeader />
            <nav
                className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
                id="ftco-navbar"
            >
                <div className="container">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#ftco-nav"
                        aria-controls="ftco-nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="fa fa-bars" /> Menu
                    </button>
                    <div className="order-lg-last">
                        <Link to="/#appointmentRef" className="btn btn-primary">
                            Make an appointment
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link to={'/'} className="nav-link">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/about'} className="nav-link">
                                    About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/team'} className="nav-link">
                                    Team
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/services'} className="nav-link">
                                    Services
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={'/departments'} className="nav-link">
                                    Departments
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={'/gallery'} className="nav-link">
                                    Gallery
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link to={'/blogList'} className="nav-link">
                                    Blog
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/contact'} className="nav-link">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* END nav */}
            <section
                className="hero-wrap hero-wrap-2"
                style={{ backgroundImage: 'url("images/bg_2.jpg")' }}
                data-stellar-background-ratio="0.5"
            >
                <div className="overlay" />
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs mb-2">
                                <span className="mr-2">
                                    <Link to={'/'}>
                                        Home <i className="fa fa-chevron-right" />
                                    </Link>
                                </span>{" "}
                                <span>
                                    Blog <i className="fa fa-chevron-right" />
                                </span>
                            </p>
                            <h1 className="mb-0 bread">Blog</h1>
                        </div>
                    </div>
                </div>
            </section>
            {console.log("blogs", blogs)}
            <section className="ftco-section">
                <div className="container">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                        {

                            blogs && blogs.length > 0 && blogs.map((item, index) => (
                                <div style={{ width: '100%',maxWidth: 350, marginBottom: '20px' }}>
                                    <InstagramEmbed
                                        key={index}
                                        url={item}
                                        style={{ marginLeft: 10 }}
                                        height="100%" />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}
