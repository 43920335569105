
import React from 'react';

const IconComponent = ({ iconSrc, width, height, tint }) => {
    const iconStyle = {
        filter: `tint(${tint})`, // Use the tint value provided as a prop,
        objectFit: 'contain', // Maintain the aspect ratio while applying the filter
    };
    return <img src={iconSrc} width={width} height={height} style={iconStyle} />;
};

export default IconComponent;