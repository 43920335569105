import React, { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AboutPhysiotherapy from '../components/AboutPhysiotherapy'
import Appointment from '../components/Appointment'
import Footer from '../components/Footer'
import MenuHeader from '../components/MenuHeader'
import TopSocial from '../components/TopSocial'
import { servicies } from '../utils/commons'
import IconComponent from '../utils/IconComponent'

export default function Home() {
  const appointmentRef = useRef(null);
  const scrollToCustomComponent = () => {
    const node = appointmentRef.current;
    console.log('scrollToCustomComponent', node);
    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  };

  useEffect(() => {
    if (window.location.hash === '#appointment') {
      scrollToCustomComponent()
    }
  }, []);
  return (
    <div>
      <TopSocial />
      <MenuHeader />
      <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
        <div className="container">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="fa fa-bars" /> Menu
          </button>
          <div className="order-lg-last">
            <a onClick={scrollToCustomComponent} className="btn btn-primary" style={{ color: 'white' }}>Make an appointment</a>
          </div>
          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active"><Link to={'/'} className="nav-link">Home</Link></li>
              <li className="nav-item"><Link to={'/about'} className="nav-link">About</Link></li>
              <li className="nav-item"><Link to={'/team'} className="nav-link">Team</Link></li>
              <li className="nav-item"><Link to={'/services'} className="nav-link">Services</Link></li>
              {/* <li className="nav-item"><Link to={'/departments'} className="nav-link">Departments</Link></li> */}
              <li className="nav-item"><Link to={'/gallery'} className="nav-link">Gallery</Link></li>
              <li className="nav-item"><Link to={'/blogList'} className="nav-link">Blog</Link></li>
              <li className="nav-item"><Link to={'/contact'} className="nav-link">Contact</Link></li>
            </ul>
          </div>
        </div>
      </nav>
      {/* END nav */}
      <div className="hero-wrap">
        <div className="home-slider owl-carousel">
          <div className="slider-item" style={{ backgroundImage: 'url(images/bg_1.jpg)' }}>
            <div className="overlay" />
            <div className="container">
              <div className="row no-gutters slider-text align-items-center justify-content-end">
                <div className="col-md-6 ">
                  <div className="text w-100">
                    <h1 style={{ color: "#FFF" }} className="mb-4">Welcome to our Physiotherapy and Fitness center</h1>
                    <h6 style={{ color: "#FFF" }}>We have started Swasthyam Physiotherapy Center at Chhapra Rd Navsari. We provide a holistic approach to the rehabilitation of patients. Dr. Payal Patel here at the clinic is an experienced physiotherapist specializing in many fields. She is an M.S. University graduated. She is an Antenatal(During Pregnancy) and Postnatal(After Delivery) exercise specialist, certified pilates trainer cupping, dry needling, and taping practitioner. She is a specialist in traumatic as well as nontraumatic pain management and also has good knowledge in the management of neuro respiratory conditions</h6>
                    <p style={{ marginTop: 50 }}><a onClick={scrollToCustomComponent} className="btn btn-primary" style={{ color: 'white' }}>Book an appointment</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Appointment ref={appointmentRef} />
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-7 heading-section text-center ">
              <span className="subheading">We offer Services</span>
              <h2>Our Benefits</h2>
            </div>
          </div>
          <div className="row">
            {
              servicies && servicies.length > 0 && servicies.map((item, index) => (
                <div className="col-md-4 services ">
                  <div className="d-block d-flex">
                    <a className="icon d-flex justify-content-center align-items-center">
                      <IconComponent
                        iconSrc={`images/${item.icon}.png`}
                        width={50}
                        height={50} />
                    </a>
                    <div className="media-body pl-3">
                      <h3 className="heading">{item.name}</h3>
                      <p>{item.shortDescription}</p>
                      <Link to='/services' state={{ position:index }} className="nav-link">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
      <section className="ftco-section ftco-no-pb ftco-no-pt">
        <div className="container-fluid px-md-0">
          <div className="row no-gutters justify-content-center pb-5 mb-3">
            <div className="col-md-7 heading-section text-center ">
              <span className="subheading">Team &amp; Staff</span>
              <h2>Our Experts Physio therapist</h2>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
            <div style={{ width: '100%', maxWidth: '300px', marginBottom: '20px' }}>
              <div className="staff bg-primary">
                <div style={{ position: 'relative', paddingBottom: '100%' }}>
                  <div className="img" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: 'url(images/staff-1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                </div>
                <div className="text pt-3 px-3 pb-4 text-center">
                  <h3>Dr Payal Patel</h3>
                  <span className="position mb-2">Chief Physiotherapist</span>
                </div>
              </div>
            </div>

            <div style={{ width: '100%', maxWidth: '300px', marginBottom: '20px', marginLeft: '10px' }}>
              <div className="staff bg-primary">
                <div style={{ position: 'relative', paddingBottom: '100%' }}>
                  <div className="img" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundImage: 'url(images/staff-1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                </div>
                <div className="text pt-3 px-3 pb-4 text-center">
                  <h3>Dr Devanshi Parmar</h3>
                  <span className="position mb-2">Assistant Physiotherapist</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <AboutPhysiotherapy />
      {/* <section className="ftco-section testimony-section bg-dark">
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-7 heading-section heading-section-white text-center ">
              <span className="subheading">Testimonies</span>
              <h2>Happy Clients &amp; Feedbacks</h2>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <div className="carousel-testimony owl-carousel ftco-owl">
                <div className="item">
                  <div className="testimony-wrap py-4">
                    <div className="icon d-flex align-items-center justify-content-center"><span className="fa fa-quote-left" /></div>
                    <div className="text">
                      <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                      <div className="d-flex align-items-center">
                        <div className="user-img" style={{ backgroundImage: 'url(images/person_1.jpg)' }} />
                        <div className="pl-3">
                          <p className="name">Roger Scott</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap py-4">
                    <div className="icon d-flex align-items-center justify-content-center"><span className="fa fa-quote-left" /></div>
                    <div className="text">
                      <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                      <div className="d-flex align-items-center">
                        <div className="user-img" style={{ backgroundImage: 'url(images/person_2.jpg)' }} />
                        <div className="pl-3">
                          <p className="name">Roger Scott</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap py-4">
                    <div className="icon d-flex align-items-center justify-content-center"><span className="fa fa-quote-left" /></div>
                    <div className="text">
                      <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                      <div className="d-flex align-items-center">
                        <div className="user-img" style={{ backgroundImage: 'url(images/person_3.jpg)' }} />
                        <div className="pl-3">
                          <p className="name">Roger Scott</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap py-4">
                    <div className="icon d-flex align-items-center justify-content-center"><span className="fa fa-quote-left" /></div>
                    <div className="text">
                      <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                      <div className="d-flex align-items-center">
                        <div className="user-img" style={{ backgroundImage: 'url(images/person_1.jpg)' }} />
                        <div className="pl-3">
                          <p className="name">Roger Scott</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap py-4">
                    <div className="icon d-flex align-items-center justify-content-center"><span className="fa fa-quote-left" /></div>
                    <div className="text">
                      <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                      <div className="d-flex align-items-center">
                        <div className="user-img" style={{ backgroundImage: 'url(images/person_2.jpg)' }} />
                        <div className="pl-3">
                          <p className="name">Roger Scott</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="ftco-section ftco-no-pt ftco-no-pb">
        <div className="container-fluid px-md-0">
          <div className="row no-gutters">
            <div className="col-md-3 ">
              <div className="work img d-flex align-items-center" style={{ backgroundImage: 'url(images/work-1.jpg)' }}>
                <a href="images/work-1.jpg" className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand" />
                </a>
                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                  <div className="text w-100 mb-3 mt-4">
                    <h2><a href="work-single.html">Foot Pain</a></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="work img d-flex align-items-center" style={{ backgroundImage: 'url(images/work-2.jpg)' }}>
                <a href="images/work-2.jpg" className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand" />
                </a>
                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                  <div className="text w-100 mb-3 mt-4">
                    <h2><a href="work-single.html">Arm Pain</a></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="work img d-flex align-items-center" style={{ backgroundImage: 'url(images/work-3.jpg)' }}>
                <a href="images/work-3.jpg" className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand" />
                </a>
                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                  <div className="text w-100 mb-3 mt-4">
                    <h2><a href="work-single.html">Shoulder Pain</a></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="work img d-flex align-items-center" style={{ backgroundImage: 'url(images/work-4.jpg)' }}>
                <a href="images/work-4.jpg" className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand" />
                </a>
                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                  <div className="text w-100 mb-3 mt-4">
                    <h2><a href="work-single.html">Back Pain</a></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="work img d-flex align-items-center" style={{ backgroundImage: 'url(images/work-5.jpg)' }}>
                <a href="images/work-5.jpg" className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand" />
                </a>
                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                  <div className="text w-100 mb-3 mt-4">
                    <h2><a href="work-single.html">Head &amp; Migraines</a></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="work img d-flex align-items-center" style={{ backgroundImage: 'url(images/work-6.jpg)' }}>
                <a href="images/work-6.jpg" className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand" />
                </a>
                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                  <div className="text w-100 mb-3 mt-4">
                    <h2><a href="work-single.html">Wear &amp; Tear</a></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="work img d-flex align-items-center" style={{ backgroundImage: 'url(images/work-7.jpg)' }}>
                <a href="images/work-7.jpg" className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand" />
                </a>
                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                  <div className="text w-100 mb-3 mt-4">
                    <h2><a href="work-single.html">Arm Pain</a></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="work img d-flex align-items-center" style={{ backgroundImage: 'url(images/work-8.jpg)' }}>
                <a href="images/work-8.jpg" className="icon image-popup d-flex justify-content-center align-items-center">
                  <span className="fa fa-expand" />
                </a>
                <div className="desc w-100 px-4 text-center pt-5 mt-5">
                  <div className="text w-100 mb-3 mt-4">
                    <h2><a href="work-single.html">Lower Back Pain</a></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center pb-5 mb-3">
            <div className="col-md-7 heading-section text-center ">
              <span className="subheading">News &amp; Blog</span>
              <h2>Latest news from our blog</h2>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-md-4 d-flex ">
              <div className="blog-entry align-self-stretch">
                <a href="blog-single.html" className="block-20 rounded" style={{ backgroundImage: 'url("images/image_1.jpg")' }}>
                </a>
                <div className="text mt-3">
                  <div className="posted mb-3 d-flex">
                    <div className="img author" style={{ backgroundImage: 'url(images/person_2.jpg)' }} />
                    <div className="desc pl-3">
                      <span>Posted by John doe</span>
                      <span>24 February 2020</span>
                    </div>
                  </div>
                  <h3 className="heading"><a href="#">What Every Athlete Needs To Know About Injury Recovery</a></h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex ">
              <div className="blog-entry align-self-stretch">
                <a href="blog-single.html" className="block-20 rounded" style={{ backgroundImage: 'url("images/image_2.jpg")' }}>
                </a>
                <div className="text mt-3">
                  <div className="posted mb-3 d-flex">
                    <div className="img author" style={{ backgroundImage: 'url(images/person_3.jpg)' }} />
                    <div className="desc pl-3">
                      <span>Posted by John doe</span>
                      <span>24 February 2020</span>
                    </div>
                  </div>
                  <h3 className="heading"><a href="#">What Every Athlete Needs To Know About Injury Recovery</a></h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex ">
              <div className="blog-entry align-self-stretch">
                <a href="blog-single.html" className="block-20 rounded" style={{ backgroundImage: 'url("images/image_3.jpg")' }}>
                </a>
                <div className="text mt-3">
                  <div className="posted mb-3 d-flex">
                    <div className="img author" style={{ backgroundImage: 'url(images/person_1.jpg)' }} />
                    <div className="desc pl-3">
                      <span>Posted by John doe</span>
                      <span>24 February 2020</span>
                    </div>
                  </div>
                  <h3 className="heading"><a href="#">What Every Athlete Needs To Know About Injury Recovery</a></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
      {/* loader */}
      {/* <div id="ftco-loader" className="show fullscreen"><svg className="circular" width="48px" height="48px"><circle className="path-bg" cx={24} cy={24} r={22} fill="none" strokeWidth={4} stroke="#eeeeee" /><circle className="path" cx={24} cy={24} r={22} fill="none" strokeWidth={4} strokeMiterlimit={10} stroke="#F96D00" /></svg></div> */}
    </div>
  )
}
