import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import MenuHeader from '../components/MenuHeader'
import TopSocial from '../components/TopSocial'

export default function Team() {
    return (
        <>
            <TopSocial />
            <MenuHeader />
            <nav
                className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
                id="ftco-navbar"
            >
                <div className="container">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#ftco-nav"
                        aria-controls="ftco-nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="fa fa-bars" /> Menu
                    </button>
                    <div className="order-lg-last">
                        <Link to="/#appointmentRef" className="btn btn-primary">
                            Make an appointment
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link to={'/'} className="nav-link">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/about'} className="nav-link">
                                    About
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Link to={'/team'} className="nav-link">
                                    Team
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/services'} className="nav-link">
                                    Services
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={'/departments'} className="nav-link">
                                    Departments
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={'/gallery'} className="nav-link">
                                    Gallery
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/blogList'} className="nav-link">
                                    Blog
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/contact'} className="nav-link">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* END nav */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }} L̥>
                <section
                    className="hero-wrap hero-wrap-2"
                    style={{ backgroundImage: 'url("images/bg_2.jpg")' }}
                    data-stellar-background-ratio="0.5"
                >
                    <div className="overlay" />
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end">
                            <div className="col-md-9 pb-5">
                                <p className="breadcrumbs mb-2">
                                    <span className="mr-2">
                                        <Link to={'/'}>
                                            Home <i className="fa fa-chevron-right" />
                                        </Link>
                                    </span>{" "}
                                    <span>
                                        Team <i className="fa fa-chevron-right" />
                                    </span>
                                </p>
                                <h1 className="mb-0 bread">Our Experts Physiotherapist</h1>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className="ftco-section">
                <div className="container">
                    <div className="row no-gutters justify-content-center pb-5 mb-3">
                        <div className="col-md-7 heading-section text-center">
                            <span className="subheading">Team &amp; Staff</span>
                            <h2>Our Experts Physiotherapist</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 d-flex">
                            <div className="staff bg-primary">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(images/staff-1.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 px-3 pb-4 text-center">
                                    <h3>Dr. Payal Patel</h3>
                                    <span className="position mb-2">Chief Physiotherapist</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex">
                            <div className="staff bg-primary">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(images/staff-1.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 px-3 pb-4 text-center">
                                    <h3>Dr. Devanshi Parmar</h3>
                                    <span className="position mb-2">Assistant Physiotherapist</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex">
                            <div className="staff bg-primary">
                                <div className="img-wrap d-flex align-items-stretch">
                                    <div
                                        className="img align-self-stretch"
                                        style={{ backgroundImage: "url(images/staff-2.jpg)" }}
                                    />
                                </div>
                                <div className="text pt-3 px-3 pb-4 text-center">
                                    <h3>Ms. Honey Patel</h3>
                                    <span className="position mb-2">Assistant</span>
                                    <div className="faded">
                                        <p>
                                            I am an ambitious workaholic, but apart from that, pretty
                                            simple person.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
